import React, { useState, useRef } from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import './Home.css'; 
import LanguageSelector from './languageSelector';

const Home = () => {
    const { t } = useTranslation();
    const [openIndex, setOpenIndex] = useState(null);
    const [copySuccess, setCopySuccess] = useState(false);
    const textAreaRef = useRef(null);

    const faqData = [
      {
        question: t('faq.question1'),
        answer: t('faq.answer1')
      },
      {
        question: t('faq.question2'),
        answer: t('faq.answer2')
      },
      {
        question: t('faq.question3'),
        answer: t('faq.answer3')
      },
      {
        question: t('faq.question4'),
        answer: t('faq.answer4')
      },
      {
        question: t('faq.question5'),
        answer: t('faq.answer5')
      },
      {
        question: t('faq.question6'),
        answer: t('faq.answer6'),
        hasCopyLink: true
      }
    ];

    const toggleFAQ = (index) => {
      setOpenIndex(openIndex === index ? null : index);
    };


    const copyToClipboard = () => {
        textAreaRef.current.select();
        document.execCommand('copy');
        setCopySuccess(true);
        setTimeout(() => setCopySuccess(false), 2000);
    };


    return (
        <div className="home">
            <header>
                <nav>
                    <div className='nav-bar'>
                        <div className='nav-bar-inner'>
                            <div className="logo">
                                <img src='/azucar-flow-logo.png' alt={t('logoAlt')} className='logo-img' />
                            </div>
                            <div className='nav-actions'>
                                <LanguageSelector />
                                <Link to="/dashboard?action=login" className="nav-link">{t('login')}</Link>
                                <Link to="/dashboard?action=signup" className="nav-button">{t('signUp')}</Link>
                            </div>
                        </div>
                    </div>
                </nav>
            </header>

            <main>
                <section className='hero'>
                    <div className='wrapper'>
                        <div className='hero-content-wrapper'>
                            <h1>{t('hero.title')}</h1>
                            <p>{t('hero.description')}</p>
                            <Link to="/dashboard?action=signup" className="cta-button">{t('hero.ctaButton')}</Link>
                        </div>
                        <div className='hero-img-wrapper'>
                            <img src='/landing-page/hero-image.png' alt={t('hero.imageAlt')} className='hero-img' />
                        </div>
                    </div>
                </section>

                <section className='problem'>
                    <div className='wrapper'>
                        <div className='problem-container'>
                            <div className='problem-content'>
                                <h2 className='section-title'>{t('problem.title')}</h2>
                                <p>
                                    {t('problem.description1')}
                                    <br /><br />
                                    {t('problem.description2')}
                                    <br /><br />
                                    {t('problem.description3')}
                                    <br /><br />
                                    {t('problem.description4')}
                                </p>
                            </div>
                            <div className='problem-pains'>
                                <div className='pain'>{t('problem.pain1')}</div>
                                <div className='pain'>{t('problem.pain2')}</div>
                                <div className='pain'>{t('problem.pain3')}</div>
                                <div className='pain'>{t('problem.pain4')}</div>
                                <div className='pain'>{t('problem.pain5')}</div>
                            </div>
                        </div>
                    </div>
                </section>

                <section className='benefits' id='benefits'>
                    <div className='wrapper'>
                        <div className='section-headline-wrapper'>
                            <h2 className='section-title'>{t('benefits.title')}</h2>
                            <p className='section-p'>
                                {t('benefits.description1')}
                                <br /><br />
                                {t('benefits.description2')}
                            </p>
                        </div>
                        <div className='benefits-container'>
                            {['benefit1', 'benefit2', 'benefit3', 'benefit4'].map((benefit, index) => (
                                <div key={index} className='benefits-item'>
                                    <div className={`benefits-image-wrapper benefit-${index + 1}`}>
                                        <img src={`/landing-page/${t(`benefits.${benefit}.image`)}`} alt={t(`benefits.${benefit}.imageAlt`)} />
                                    </div>
                                    <div className='benefits-content'>
                                        <h3>{t(`benefits.${benefit}.title`)}</h3>
                                        <p>{t(`benefits.${benefit}.description`)}</p>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </section>

                <section className='faqs' id="faq">
                    <div className='wrapper'>
                        <div className='section-headline-wrapper'>
                            <h2 className='section-title'>{t('faq.title')}</h2>
                        </div>
                        <div className='faq-bg'>
                            <div className='faq-container'>
                                {faqData.map((faq, index) => (
                                    <div key={index} className={`faq-item ${openIndex === index ? 'active' : ''}`}>
                                        <div className='faq-question' onClick={() => toggleFAQ(index)}>
                                            <h3>{faq.question}</h3>
                                            <span className='faq-toggle'>{openIndex === index ? '+' : '+'}</span>
                                        </div>
                                        <div className='faq-answer'>
                                            <p>{faq.answer}</p>
                                            {faq.hasCopyLink && (
                                                <div className="copy-link-container">
                                                    <p>{t('faq.copyLinkText')}</p>
                                                    <div className="copy-link-input">
                                                        <input 
                                                            ref={textAreaRef}
                                                            value="https://azucarflow.com/"
                                                            readOnly
                                                        />
                                                        <button onClick={copyToClipboard}>
                                                            {copySuccess ? t('faq.linkCopiedText') : t('faq.copyButtonText')}
                                                        </button>
                                                    </div>
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                </section>

                <section className='cta'>
                    <div className='wrapper'>
                        <div className='cta-inner'>
                            <h2 className='section-title'>{t('cta.title')}</h2>
                            <p>{t('cta.description')}</p>
                            <Link to="/dashboard?action=signup" className="cta-button">{t('cta.button')}</Link>
                        </div>
                    </div>
                </section>

                <section className='footer'>
                    <div className='wrapper'>
                        <div className='footer-inner'>
                            <p>{t('footer.createdBy')} <Link to="https://www.priscilabarkmann.com/" className="create-link">Priscila Barkmann</Link> & <Link to="https://mtzangel.com/" className="create-link">Angel Martinez</Link></p>
                            <div className='footer-links'>
                                <Link to="/privacy-policy" className="footer-link">{t('footer.privacyPolicy')}</Link>
                                <Link to="/terms" className="footer-link">{t('footer.terms')}</Link>
                            </div>
                        </div>
                    </div>
                </section>
            </main>
        </div>
    );
};

export default Home;