import React, { useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import BeatMappingCMS from './BeatMappingCMS';
import RhythmGame from './RhythmGame';
import { AuthProvider } from './AuthContext';
import Home from './Home';
import { I18nextProvider, useTranslation } from 'react-i18next';
import i18n from './i18n';

function AppContent() {
  const { i18n } = useTranslation();

  useEffect(() => {
    const storedLang = localStorage.getItem('userLanguage');
    if (storedLang) {
      i18n.changeLanguage(storedLang);
    }
  }, [i18n]);

  return (
    <AuthProvider>
      <Router>
        <div className="App">
          <nav>
            {/*<ul>
              <li>
                <Link to="/">Home</Link>
              </li>
              <li>
                <Link to="/cms">Beat Mapping CMS</Link>
              </li>
              <li>
                <Link to="/game">Rhythm Game</Link>
              </li>
            </ul>*/}
          </nav>

          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/cms" element={<BeatMappingCMS />} />
            <Route path="/dashboard" element={<RhythmGame />} />
          </Routes>
        </div>
      </Router>
    </AuthProvider>
  );
}

function App() {
  return (
    <I18nextProvider i18n={i18n}>
      <AppContent />
    </I18nextProvider>
  );
}

export default App;