import React, { useState, useEffect } from 'react';
import { useAuth } from './AuthContext';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import './Auth.css';

const Auth = () => {
  const [loading, setLoading] = useState(false);
  const [email, setEmail] = useState('');
  // NEW: Added state for name
  const [name, setName] = useState('');
  const [isNewUser, setIsNewUser] = useState(false);
  const { signIn } = useAuth();
  const location = useLocation();
  const { t } = useTranslation();

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const action = searchParams.get('action');
    setIsNewUser(action === 'signup');
  }, [location]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      setLoading(true);
      // CHANGED: Added name to signIn function call
      const response = await signIn({ email, name });
      console.log('Sign in response:', response);

      if (response && response.error) {
        throw response.error;
      }

      alert(t('checkEmailAlert', { action: isNewUser ? t('signUp') : t('logIn') }));
    } catch (error) {
      console.error('Authentication error:', error);
      alert(error.message || t('authError'));
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="auth-container">
      <form onSubmit={handleSubmit} className='auth-form'>
        <img src='/azucar-flow-logo.png' alt="Azucar Flow Logo" className='auth-form-logo' />
        <h1>{t('welcome')}</h1>
        <p>{isNewUser ? t('createAccount') : t('signInAccount')}</p>
        <div className='auth-form-inputs'>
          {/* NEW: Added conditional rendering for name input */}
          {isNewUser && (
            <input
              type="text"
              placeholder={t('Name')}
              value={name}
              className='auth-input'
              onChange={(e) => setName(e.target.value)}
              required
            />
          )}
          <input
            type="email"
            placeholder={t('Email')}
            value={email}
            className='auth-input'
            onChange={(e) => setEmail(e.target.value)}
            required
          />
          <button type="submit" className='auth-button' disabled={loading}>
            {loading ? t('sending') : t('getLoginLink')}
          </button>
        </div>
        <div className='auth-options'>
          <button 
            type="button" 
            onClick={() => setIsNewUser(!isNewUser)} 
            className='auth-toggle'
          >
            {isNewUser ? t('alreadyHaveAccount') : t('needAccount')}
          </button>
        </div>
      </form>
    </div>
  );
};

export default Auth;